import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/nftx-developers.github.io/nftx-developers.github.io/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Gatsby themes allow you to override configuration from the theme by defining the
same property in your `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{` at the root of your project. You can
see the default configuration provided by the theme’s
`}<a parentName="p" {...{
          "href": "https://github.com/carbon-design-system/gatsby-theme-carbon/blob/main/packages/gatsby-theme-carbon/gatsby-config.js"
        }}><inlineCode parentName="a">{`gatsby-config.js`}</inlineCode></a>{`
file.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Site metadata</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Manifest</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Theming</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Favicon</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Image compression</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Global search</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Navigation style</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Edit on Github link</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Medium</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Other options</AnchorLink>
    </AnchorLinks>
    <h2>{`Site metadata`}</h2>
    <p>{`To add a title and description to each page, simply provide them to siteMetadata
in your `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{` file.`}</p>
    <p>{`The language attribute applied to the `}<inlineCode parentName="p">{`<html>`}</inlineCode>{` tag on every page is English
(`}<inlineCode parentName="p">{`en`}</inlineCode>{`) by default, but you can choose to override this. For more information on
declaring the language of a page in HTML, please review
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/language-of-page"
      }}>{`W3 Criterion 3.1.1 Language of a Page`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.exports = {
  siteMetadata: {
    title: 'Gatsby Theme Carbon',
    description: 'A Gatsby theme for the carbon design system',
    keywords: 'gatsby,theme,carbon',
    lang: 'en',
  },
  plugins: ['gatsby-theme-carbon'],
};
`}</code></pre>
    <h2>{`Manifest`}</h2>
    <p>{`One of the first configurations should be to override the default manifest
options, you can do this in `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{`. Any options you don’t set, will
be provided by the theme. See the example project.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`siteMetadata: {
    title: 'Gatsby Theme Carbon',
  },
  plugins: [
    'gatsby-theme-carbon',
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        name: 'Carbon Design Gatsby Theme',
        short_name: 'Gatsby Theme Carbon',
        start_url: '/',
        background_color: '#ffffff',
        theme_color: '#0062ff',
        display: 'browser',
      },
    },
  ],
`}</code></pre>
    <h2>{`Theming`}</h2>
    <p>{`With theming, you can control the colors being used on the homepage as well as
the interior pages. Your choices include `}<inlineCode parentName="p">{`white`}</inlineCode>{`, `}<inlineCode parentName="p">{`dark`}</inlineCode>{`, and `}<inlineCode parentName="p">{`g10`}</inlineCode>{`. The default
for the homepage and the interior theme are dark and g10, respectively.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        theme: {
          homepage: 'dark',
          interior: 'g10',
        },
      },
    },
  ],
`}</code></pre>
    <h2>{`Favicon`}</h2>
    <p>{`If you need to override the default favicon, you can do so by passing a relative
path to the icon. It’s recommended to provide a 512 x 512 version.`}</p>
    <p><strong parentName="p">{`IMPORTANT:`}</strong>{` For best results, if you’re providing an icon for generation it
should be…`}</p>
    <ul>
      <li parentName="ul">{`at least as big as the largest icon being generated (512x512 by default).`}</li>
      <li parentName="ul">{`square (if it’s not, transparent bars will add to make it square).`}</li>
      <li parentName="ul">{`of one of the follow formats: JPEG, PNG, WebP, TIFF, GIF or SVG.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        iconPath: './src/images/custom-icon-512.jpg'
      },
    },
  ],
`}</code></pre>
    <h2>{`Image compression`}</h2>
    <p>{`You can enable WebP by passing `}<inlineCode parentName="p">{`withWebp: true`}</inlineCode>{` or providing your own
optimization level. See the gatsby-remark-images
`}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/packages/gatsby-remark-images/#options"
      }}>{`plugin options`}</a>{`.
You can also tweak the image quality based on design tolerance and performance
thresholds.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.exports = {
  plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        withWebp: true, // true, false (default false)
        imageQuality: 50, // 0-100 (default 75)
      },
    },
  ],
};
`}</code></pre>
    <h2>{`Global search`}</h2>
    <p>{`Site-wide search is provided by the theme. The only requirement for a page to
show up in the results is for it to have `}<inlineCode parentName="p">{`title`}</inlineCode>{` set in the
`}<a parentName="p" {...{
        "href": "/components/MDX#frontmatter"
      }}>{`frontmatter`}</a>{`. To render more helpful search
results (and improve SEO), you’ll want to make sure your pages have
`}<inlineCode parentName="p">{`description`}</inlineCode>{` set in the frontmatter as well.`}</p>
    <p>{`Global search is enabled by default. To disable it, set the `}<inlineCode parentName="p">{`isSearchEnabled`}</inlineCode>{`
option to false.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        isSearchEnabled: false
      },
    },
  ],
`}</code></pre>
    <p>{`Under the hood, we use `}<a parentName="p" {...{
        "href": "https://lunrjs.com/"
      }}>{`Lunr`}</a>{` to create our search index.
If necessary, you tweak the search scoring algorithm and source nodes. To do so,
provide your own
`}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/packages/gatsby-plugin-lunr/#getting-started"
      }}>{`resolvers object`}</a>{`
to the `}<inlineCode parentName="p">{`lunrOptions`}</inlineCode>{` theme option.`}</p>
    <h2>{`Navigation style`}</h2>
    <p>{`By default, the navigation style used by the theme is a sidebar that sits on the
left-hand side of the screen. You can see it right now as you read this
documentation. This style works great for websites with a lot of content, like
documentation sites.`}</p>
    <p>{`However, if your site is more editorial in nature, and has less pages of
content, you may want to use the `}<inlineCode parentName="p">{`header`}</inlineCode>{` navigation. This will remove the
sidebar on the left-hand side of the page and replace it with a navigation menu
that sits in the header. If in the future, the content on your site evolves and
you feel the left nav is better suited, you can always change it back.`}</p>
    <p>{`To enable the header nav, provide `}<inlineCode parentName="p">{`header`}</inlineCode>{` as a string to the `}<inlineCode parentName="p">{`navigationStyle`}</inlineCode>{`
option. To switch back to the default left nav provide an empty string `}<inlineCode parentName="p">{`''`}</inlineCode>{` to
the `}<inlineCode parentName="p">{`navigationStyle`}</inlineCode>{` option. Note: By using the `}<inlineCode parentName="p">{`header`}</inlineCode>{` navigation style, you
will lose the ResourceLinks that sit directly below the left nav.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        navigationStyle: 'header',
      },
    },
  ],
`}</code></pre>
    <p>{`When enabled, your header navigation will look like the image below:`}</p>
    <Row mdxType="Row">
      <Column colMd={8} colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHBkYFiof/EABcQAAMBAAAAAAAAAAAAAAAAAAACEDL/2gAIAQEAAQUCqj4P/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGxAAAgEFAAAAAAAAAAAAAAAAAAExEBEhUXH/2gAIAQEAAT8hyNPTLkibtP/aAAwDAQACAAMAAAAQ08//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAdEAEAAQMFAAAAAAAAAAAAAAABABARMVFhcaHB/9oACAEBAAE/EAuyrteALoGqMGYZn4nT+U//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Header navigation style",
            "title": "Header navigation style",
            "src": "/static/e57ea23bd169fd3d0dac4e22c4a4a31c/2e753/header-nav-config.jpg",
            "srcSet": ["/static/e57ea23bd169fd3d0dac4e22c4a4a31c/69549/header-nav-config.jpg 288w", "/static/e57ea23bd169fd3d0dac4e22c4a4a31c/473e3/header-nav-config.jpg 576w", "/static/e57ea23bd169fd3d0dac4e22c4a4a31c/2e753/header-nav-config.jpg 1152w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <p>{`With the header navigation style enabled, the content on your page will be
further left-aligned to allow for more content space.`}</p>
    <p><strong parentName="p">{`Just a note:`}</strong>{` In mobile-view, when the header navigation is enabled, the
sidebar will persist.`}</p>
    <h2>{`Edit on GitHub link`}</h2>
    <p>{`To add a link to the bottom of each page that points to the current page source
in GitHub, provide a `}<inlineCode parentName="p">{`repository`}</inlineCode>{` object to `}<inlineCode parentName="p">{`siteMetadata`}</inlineCode>{` in your
`}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{` file. You can provide a `}<inlineCode parentName="p">{`baseUrl`}</inlineCode>{`, and if needed, the
`}<inlineCode parentName="p">{`subDirectory`}</inlineCode>{` and `}<inlineCode parentName="p">{`branch`}</inlineCode>{` where your site source lives.`}</p>
    <p>{`To disable the `}<inlineCode parentName="p">{`Edit in Github`}</inlineCode>{` link, remove the `}<inlineCode parentName="p">{`repository`}</inlineCode>{` option. If the
options object is now empty, delete it all together.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        repository: {
          baseUrl: 'https://github.com/carbon-design-system/gatsby-theme-carbon',
          subDirectory: '/packages/example',
          branch: 'main',
        },
      },
    },
  ],
`}</code></pre>
    <h2>{`Medium`}</h2>
    <p>{`In order to change the source account for the
`}<a parentName="p" {...{
        "href": "/components/MediumPosts"
      }}><inlineCode parentName="a">{`MediumPosts`}</inlineCode></a>{` component, provide an account with the
`}<inlineCode parentName="p">{`mediumAccount`}</inlineCode>{` option.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`plugins: [
  {
    resolve: 'gatsby-theme-carbon',
    options: {
      mediumAccount: 'carbondesign',
    },
  },
];
`}</code></pre>
    <h2>{`Other options`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`mdxExtensions`}</inlineCode>{` – change the file extensions processed by `}<inlineCode parentName="p">{`gatsby-mdx`}</inlineCode>{`
(default `}{`[‘.mdx’, ‘.md’]`}{`).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`isSwitcherEnabled`}</inlineCode>{` - pass `}<inlineCode parentName="p">{`false`}</inlineCode>{` to remove the Site switcher from the header
navigation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`titleType`}</inlineCode>{` – pick between four formats for the `}<inlineCode parentName="p">{`<title>`}</inlineCode>{` element for your
site. Here are the four options using this page as an example:`}</p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`page`}</inlineCode>{`: “Configuration” (default)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`site`}</inlineCode>{`: “Gatsby Theme Carbon”`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`append`}</inlineCode>{`: “Gatsby Theme Carbon – Configuration”`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`prepend`}</inlineCode>{`: “Configuration - Gatsby Theme Carbon”`}</li>
        </ul>
      </li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        mdxExtensions: ['.mdx'],
        titleType: 'append'
      },
    },
  ],
`}</code></pre>
    <h2>{`Additional remark plugins and override existing remark plugin configuration`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`gatsbyRemarkPlugins`}</inlineCode>{` - An array containing gatsby remark plugin
configurations to be added/overridden.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`remarkPlugins`}</inlineCode>{` - An array containing
`}<a parentName="li" {...{
          "href": "https://www.gatsbyjs.org/packages/gatsby-plugin-mdx/#remark-plugins"
        }}>{`remark plugin configurations`}</a>{`
to be added.`}</li>
    </ul>
    <p>{`The `}<inlineCode parentName="p">{`gatsbyRemarkPlugins`}</inlineCode>{` option is compatible with all gatsby-remark-`}{`*`}{`
plugins, for example `}<inlineCode parentName="p">{`gatsby-remark-mermaid`}</inlineCode>{`. If a gatsby remark plugin does not
work, you can try calling the (underlying) remark plugin directly using the
`}<inlineCode parentName="p">{`remarkPlugins`}</inlineCode>{` option.`}</p>
    <h3>{`Example `}<a parentName="h3" {...{
        "href": "https://www.gatsbyjs.org/packages/gatsby-remark-mermaid/"
      }}>{`Gatsby Remark Mermaid plugin`}</a></h3>
    <p>{`For the below markdown snippet:`}</p>
    <pre><code parentName="pre" {...{}}>{`\`\`\`mermaid
graph LR
install[Install Plugin]
install --> configure[Configure Plugin]
configure --> draw[Draw Fancy Diagrams]
\`\`\`
`}</code></pre>
    <p>{`to turn into an image one should add the following configuration to her/his own
project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{` plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        repository: {
          baseUrl: 'https://github.com/carbon-design-system/gatsby-theme-carbon',
          subDirectory: '/packages/example',
          branch: 'main',
        },
        gatsbyRemarkPlugins: [
          {
            resolve: 'gatsby-remark-mermaid',
            options: {
              language: 'mermaid',
              theme: 'dark',
              viewport: {
                width: 200,
                height: 200,
              },
              mermaidOptions: {
                themeCSS: '.node rect { fill: cornflowerblue; }',
              },
            },
          },
        ],
      },
    },
  ],

`}</code></pre>
    <h3>{`Example `}<a parentName="h3" {...{
        "href": "https://github.com/zestedesavoir/zmarkdown/tree/master/packages/remark-grid-tables"
      }}>{`Remark Grid Tables`}</a></h3>
    <p>{`For the below markdown snippet:`}</p>
    <pre><code parentName="pre" {...{}}>{`+-------+----------+------+
| Table Headings   | Here |
+-------+----------+------+
| Sub   | Headings | Too  |
+=======+==========+======+
| cell  | column spanning |
+ spans +----------+------+
| rows  | normal   | cell |
+-------+----------+------+
| multi | cells can be    |
| line  | *formatted*     |
|       | **paragraphs**  |
| cells |                 |
| too   |                 |
+-------+-----------------+
`}</code></pre>
    <p>{`After installing the `}<inlineCode parentName="p">{`remark-grid-tables`}</inlineCode>{` plugin, add it to the `}<inlineCode parentName="p">{`remarkPlugins`}</inlineCode>{`
array in the theme options.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{` plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        remarkPlugins: [require("remark-grid-tables")],
      },
    },
  ],
`}</code></pre>
    <h2>{`Adding new MDX layouts`}</h2>
    <p><inlineCode parentName="p">{`gatsby-plugin-mdx`}</inlineCode>{` and `}<inlineCode parentName="p">{`gatsby-source-filesystem`}</inlineCode>{` can work together to
automatically template MDX files in a given directory. See the
`}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.com/plugins/gatsby-plugin-mdx/#default-layouts"
      }}>{`plugin documentation`}</a>{`.`}</p>
    <p>{`The Carbon theme already comes with a default layout, but you can add your own
with the `}<inlineCode parentName="p">{`extraLayouts`}</inlineCode>{` option.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{` plugins: [
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        extraLayouts: {
          posts: require.resolve('./src/layouts/postLayout.js')
        }
      },
 ],
`}</code></pre>
    <p>{`Note that the extra layouts do not inherit from theme default. Custom layouts
start from a clean slate. If you want to iterate on the Carbon template, copy
`}<inlineCode parentName="p">{`/gatsby-theme-carbon/src/templates/Default.js`}</inlineCode>{` to your custom template as a
starting point. When doing this, you may also need to change the import paths to
the correct location.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      